import React                 from 'react';
import { graphql }           from 'gatsby';
import Img                   from 'gatsby-image';
import { Flex, Box, Button } from '@chakra-ui/react';

import Wrapper       from '@interness/web-core/src/components/structure/Wrapper/Wrapper';
import Heading       from '@interness/web-core/src/components/text/Heading/Heading';
import Spacer        from '@interness/web-core/src/components/structure/Spacer/Spacer';
import SEO           from '@interness/web-core/src/components/modules/SEO/SEO';
import Carousel      from '@interness/web-core/src/components/media/Carousel/Carousel';
import MoreExpander  from '@interness/web-core/src/components/structure/MoreExpander/MoreExpander';
import CallToAction  from '@interness/web-core/src/components/modules/CallToAction/CallToAction';
import Center        from '@interness/web-core/src/components/structure/Center/Center';
import Link          from '@interness/web-core/src/components/elements/Link/Link';
import { findMedia } from '@interness/web-core/src/components/_helpers';


import Partners from '../components/Partners';

function IndexPage(props) {
  const services = [
    {
      title: 'Schieferarbeiten',
      description: 'Schiefer ein traditioneller Baustoff der den Wandel der Zeit mit Bravour bestanden hat.',
      imageId: 'schieferarbeiten',
      linkTo: 'schieferarbeiten'
    },
    {
      title: 'Bedachungen',
      description: 'Wohnräume im Dach bieten Ihnen neben zusätzlich nutzbarer Fläche ein ganz besonderes, behagliches ...',
      imageId: 'bedachung',
      linkTo: 'bedachungen'
    },
    {
      title: 'Fassaden',
      description: 'Das Dach mit Fassade schützt Ihr Haus vor Witterungseinflüssen und spart Heizkosten mit ...',
      imageId: 'fassaden',
      linkTo: 'fassaden'
    },
    {
      title: 'Bauklempnerei / Zimmerei',
      description: 'Zum Umfang unserer Leistungen gehört die Montage aller zusammenhängenden Bauteile, ...',
      imageId: 'bauklempnerei',
      linkTo: 'bauklempnerei-zimmerei'
    },
    {
      title: 'Isolierungen',
      description: 'Damit Sie sich in Ihrem Haus nicht nur behaglich fühlen, sondern auch von hohen Energiekosten...',
      imageId: 'isolierungen',
      linkTo: 'isolierungen'
    },
    {
      title: 'Service',
      description: 'Guten Service zu leisten ist für uns eine Selbstverständlichkeit. Das bedeutet eine ...',
      imageId: 'service-4',
      linkTo: 'service'
    }
  ]
  return (
    <>
      <SEO title={'Startseite'}/>
      <Carousel>
        {props.data.headerImages.images.map((image) => (
          <Img key={image.name} fluid={image.file.localFile.childImageSharp.fluid}
               alt={image.file.localFile.name}/>
        ))}
      </Carousel>
      <Wrapper>
        <Spacer/>
        <Heading subtitle="Herzlich Willkommen">Dachdecker Gehrke</Heading>
        <h3>Wir lassen Sie nicht im Regen stehen.</h3>
        <p>
          Wir, als Dachdeckerei Gehrke in Halver, bieten professionelle Lösungen im Bereich Schieferarbeiten,
          Bedachungen, Fassadenverkleidung, Zimmerei, Isolierungen und Dämmung.
          <br/><br/>
          Und wenn es mal wirklich dringend ist, helfen wir gern mit unserem Dachnotdienst!
          <br/><br/>
          Das Angebot an heutigen Dacheindeckungen ist für den Bauherren kaum überschaubar. Neben der Wahl des passenden
          Bedachungsmaterials stehen sicherlich weiterhin viele Fragen im Raum. Angefangen von den Produkten selber, den
          Dachaufbauten bis zum Innenausbau Ihres Dachgeschosses.
        </p>
        <MoreExpander>
          Neben den unterschiedlichen Materialien und verlegetechnischen Möglichkeiten gilt es heute auch eine Vielzahl
          von Anforderungen und Bestimmungen zu beachten. Neben der Wirtschaftlichkeit sind natürlich auch Fragen nach
          Energieeinsparpotentialen und Möglichkeiten zu beantworten. Ob Gewerbebau, Bürogebäude oder Wohnhaus -
          individuelle Flachdachkonstruktionen machen
          <br/><br/>
          auch Lagerhallen, Garagen, Loggien und Balkone zu anspruchsvollen und optisch reizvollen Bauwerken. Langlebig
          sind Flachdächer in erster Linie immer dann, wenn sie fachgerecht geplant und mit erstklassigen Materialien
          eingebaut werden. Dekorativ wirken Flachdächer immer dann, wenn sie begrünt sind. Sicher ist Ihr Flachdach
          dann, wenn hochwertiges Material fehlerfrei verarbeitet wurde. Energiegewinnend werden Flachdächer vor allem
          durch den Einsatz von Solarthermie oder Photovoltaik.
          <br/><br/>
          Vordächer bieten viele Vorteile in Sachen Niederschlags- oder auch Sonnenschutz. Egal, ob für
          Hauseingangbereiche oder Terrassen, Vordächer können in vielen Varianten realisiert werden. Von traditionellen
          massiven Holzbaukonstruktionen mit ihren vielschichtigen Eindeckungsmöglichkeiten, bis zu moderne
          Metallkonstruktionen mit Lichtstegplatten oder Verglasung.
          <br/><br/>
          Sie ärgern sich jedesmal wenn z.B. Tauben auf Ihrem Dach, Gesimse oder sonstigen Vorsprüngen verweilen und
          entsprechend alles verunreinigen.
          Mittlerweile gibt es eine Vielzahl von Lösungen für Ihr Problem. Wir beraten Sie gerne ausführlicher.
        </MoreExpander>
        <Spacer/>
        <section>
          <Heading tag={'h2'}>Was wir für Sie tun können</Heading>
          <Flex flexWrap="wrap" flexDirection="row" justifyContent='space-between'>
            {services.map(service => (
              <Box maxW='350px' mb='20px' display='flex' flexDirection='column' justifyContent='inherit'>
                <Img fluid={findMedia(props.data.services.images, service.imageId).childImageSharp.fluid}/>
                <h3 style={{marginTop: '10px'}}>{service.title}</h3>
                <p>{service.description}</p>
                <Button variant='outline' as={Link} to={service.linkTo}>Mehr erfahren</Button>
              </Box>
            ))}
          </Flex>
        </section>
        <Spacer/>
        <section>
          <Heading tag={'h2'}>Unsere Partner</Heading>
          <Partners/>
        </section>
        <Spacer/>
        <Center>
          <CallToAction/>
        </Center>
        <Spacer/>
        <section>
          <MoreExpander title={'Allgemeine Geschäftsbedingungen (AGB)'}>
            Allgemeine Geschäftsbedingungen (AGB)
            <br/><br/>
            - Dachdecker Wolfgang Gehrke -
            <br/><br/>
            § 1 Vertragsgrundlage
            Vertragsgrundlage für von uns als Auftragnehmer übernommene Aufträge sind die nachstehenden
            Geschäftsbedingungen. Diese AGB gelten im Geschäftsverkehr mit privaten (§13 BGB) und gewerblichen Kunden.
            Sie finden keine Anwendung bei einer Vergabe nach VOB/A.
            <br/><br/>
            § 2 Angebot - Preise
            Preisbindung Angebote haben eine Gültigkeit von 4 Wochen ab dem Angebotsdatum. Mit der Angebotsannahme
            gelten die Angebotspreise weitere vier Monate als Vertragspreise. Tritt danach eine wesentliche Veränderung
            (größer oder kleiner 0,75 %) der Preisermittlungsgrundlage im Bereich der Lohnkosten ein, erhöht bzw.
            verringert sich der Angebotspreis in angemessenem Umfang. Vorbehaltlich eines jeder Partei zustehenden
            Einzelfallnachweises beträgt die Preisänderung 0,85 % je 1 % Lohnkostenänderung.
            Eine Umsatzsteuererhöhung kann an den Auftraggeber weiterberechnet werden, wenn die Leistung nach Ablauf von
            vier Monaten seit Vertragsschluss erbracht wird. Die Leistung ist so kalkuliert, dass bei der Ausführung
            Baufreiheit bestehen sollte und dass die Leistung zusammenhängend und ohne Unterbrechung, nach Planung des
            Auftragnehmers erbracht werden kann. Bei Abweichungen (z. B. bei Behinderungen, Leistungsstörungen) besteht
            ein Anspruch auf Erstattung der Mehrkosten.
            Das Angebot bleibt mit allen Teilen geistiges Eigentum des Auftragnehmers. Die Weitergabe oder sonstige
            Verwendung kann im Einzelfall gestattet werden.
            <br/><br/>
            § 3 Witterungsbedingungen
            Bei ungeeigneten Witterungs- und Trocknungsbedingungen kann der Auftragnehmer die Arbeiten unterbrechen. Die
            Dauer der Unterbrechung verlängert die Ausführungsfrist, wenn es sich um ungewöhnliche Witterungsbedingungen
            handelt. Die Arbeiten sind bei geeigneten Witterungsbedingungen unter Berücksichtigung angemessener
            Organisations- und Rüstzeiten fortzuführen.
            <br/><br/>
            § 4 Vergütung
            Gemäß § 632a BGB können Abschlagsrechnungen jederzeit gestellt werden und sind sofort fällig und sofort
            zahlbar. Dies gilt auch für die Bereitstellung von Materialien, Stoffen oder Bauteilen. Die Schlusszahlung
            ist 10 Tage nach Rechnungszugang fällig. Skonto muss gesondert und ausdrücklich vereinbart sein.
            <br/><br/>
            § 5 Gewährleistung
            Die Gewährleistungsfrist beginnt mit der Abnahme und ist die Frist, innerhalb dieser Mängel an der Leistung
            geltend gemacht werden können (Verjährungsfrist). Die Leistungen werden vom Auftragnehmer nach den allgemein
            anerkannten Regeln der Technik ausgeführt, hierfür übernimmt er die Gewähr. Für Beschädigungen der
            Leistungen, die durch unsachgemäßen Gebrauch, Beschädigung oder Bearbeitung durch Dritte oder durch
            sonstige, nicht durch vom Auftragnehmer zu vertretende Umstände hervorgerufen sind, haftet dieser nicht.
            Verschleiß und Abnutzungserscheinungen, die auf vertragsgerechtem Gebrauch und / oder natürlicher, Abnutzung
            beruhen, sind keine Mängel. Sie können bereits vor Ablauf der Gewährleistungsfrist eintreten. Dies gilt
            besonders für alle elektrisch/mechanischen Antriebsteile von Lichtkuppelöffnungen, Dachfensteranlagen etc.
            Im übrigen gilt die Verjährungsfrist gem. § 634a BGB wie folgt:
            - 2 Jahre für Wartungs-, Renovierungs- und Instandhaltungsarbeiten (Arbeiten, die nicht die Gebäudesubstanz
            betreffen)
            - 5 Jahre bei Neubauarbeiten und Arbeiten, die nach Umfang und Bedeutung mit Neubauarbeiten vergleichbar
            sind (z. B. Grundsanierung) oder Arbeiten, welche die Gebäudesubstanz betreffen
            <br/><br/>
            § 6 Aufrechnungsverbot
            Der Auftraggeber kann die Zahlungsansprüche des Auftragnehmers nicht mit Forderungen aus anderen
            vertraglichen Beziehungen aufrechnen, es sei denn, die Forderung ist unbestritten oder rechtskräftig
            festgestellt.
            <br/><br/>
            § 7 Eigentumsvorbehalt
            Soweit der Auftragnehmer im Rahmen seiner Leistungen auch Lieferungen erbringt, behält er sich hieran das
            Eigentum bis zur vollständigen Zahlung der erbrachten Leistungen vor. Wird ein Liefergegenstand mit einem
            Bauwerk fest verbunden, so tritt der Auftraggeber etwaige damit zusammenhängende eigene Forderungen (z.B.
            bei Weiterverkauf des Objektes) in Höhe der Forderung des Auftragnehmers an diesen ab.
            <br/><br/>
            § 8 Abnahme
            Der Aufragnehmer hat Anspruch auf Teilabnahme für in sich abgeschlossene Teile der Leistung. Im Übrigen
            erfolgt die Abnahme nach § 640 BGB. Der Abnahme steht es gleich, wenn der Besteller das Werk nicht innerhalb
            einer ihm vom Unternehmer bestimmten angemessenen Frist abnimmt. Die Abnahme kann auch durch schlüssiges
            Verhalten erfolgen.
            <br/><br/>
            § 9 Leistungsermittlung, Aufmaß und Abrechnung
            Bei einem Pauschalpreisvertrag erfolgt die Abrechnung nach den vertraglichen Vereinbarungen. Ist ein
            Einheitspreisvertrag vereinbart, erfolgt die Abrechnung auf Basis einer Leistungsermittlung durch Aufmaß.
            Dabei wird die Leistung nach den Maßen der fertigen Oberfläche berechnet. Als Ausgleich für den nicht
            berechneten Bearbeitungsaufwand zur Abarbeitung an nicht behandelte Teilflächen (so genannte Aussparungen),
            zum Beispiel Fenster- und Türöffnungen, Lüftungsöffnungen werden diese Flächen bis zu einer Einzelgröße von
            2,5 qm übermessen. Bei Längenmaßen bleiben Unterbrechungen bis 1 m Einzelgröße unberücksichtigt.
            Auftraggeber und Auftragnehmer können weitere detaillierte Aufmaß regeln durch Vereinbarung der jeweils
            einschlägigen ATV VOB/C-Norm zugrunde legen.
            <br/><br/>
            § 10 Sonstiges
            Ist der Auftraggeber Verbraucher, so gilt der gesetzliche Gerichtsstand. Ansonsten ist Erfüllungsort und
            ausschließlicher Gerichtsstand für alle Streitigkeiten der Geschäftssitz des Auftragnehmers, sofern sich aus
            der Auftragsbestätigung nichts anderes ergibt.
            Sollte eine der vorstehenden Regelungen - gleich aus welchem Rechtsgrund - unwirksam sein, so wird dadurch
            die Wirksamkeit und Verbindlichkeit der übrigen Bestimmungen nicht berührt.
          </MoreExpander>
        </section>
        <Spacer/>
      </Wrapper>
    </>
  )
}

export default IndexPage;

export const query = graphql`
    query {
        headerImages: directusMediaCollection(name: {eq: "home"}) {
            name
            images: media {
                file {
                    localFile {
                        name
                        childImageSharp {
                            fluid(maxWidth: 1980, maxHeight: 700, cropFocus: CENTER) {
                                ...GatsbyImageSharpFluid
                            }
                        }
                    }
                }
            }
        }
        services: directusMediaCollection(name: {eq: "index-services"}) {
            name
            images: media {
                file {
                    localFile {
                        name
                        childImageSharp {
                            fluid {
                                ...GatsbyImageSharpFluid
                            }
                        }
                    }
                }
            }
        }
    }
`;