import React                       from 'react';
import Img                         from 'gatsby-image';
import { graphql, useStaticQuery } from 'gatsby';
import { Flex, Box }               from '@chakra-ui/react';

const Partners = () => {
  const { partners } = useStaticQuery(query);
  return (<Flex flexWrap="wrap" flexDirection="row" justifyContent="space-between">
    {partners.images.map(image => (<Box><Img fixed={image.file.localFile.childImageSharp.fixed}/></Box>))}
  </Flex>)
};

const query = graphql`
    query {
        partners: directusMediaCollection(name: {eq: "partner"}) {
            name
            images: media {
                file {
                    localFile {
                        name
                        childImageSharp {
                            fixed {
                                ...GatsbyImageSharpFixed
                            }
                        }
                    }
                }
            }
        }
    }
`;

export default Partners;